@font-face {
   font-family: 'CustomHeading';
   src:
      url('./Bohemian Typewriter.ttf') format('embedded-opentype'),
      /* Internet Explorer */ url('./Bohemian Typewriter.ttf') format('woff2'),
      /* Super Modern Browsers */ url('./Bohemian Typewriter.ttf') format('woff'),
      /* Pretty Modern Browsers */ url('./Bohemian Typewriter.ttf') format('truetype'),
      /* Safari, Android, iOS */ url('./Bohemian Typewriter.ttf') format('svg'); /* Legacy iOS */
}

.Quote {
   background-color: black;
   min-height: 100vh;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   font-size: calc(10px + 2vw);
   color: white;
   font-family: 'Bohemian Typewriter';
}

.Quote-bottom {
   padding-left: 40vw;
}
